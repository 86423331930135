<template>
  <div class="update-stock">
    <KTCodePreview v-bind:title="'Thông tin phiếu nhập kho'">
      <template v-slot:toolbar>
        <div class="row" v-if="checkPermission('STOCK_IMPORT_UPDATE')">
          <div>
            <b-row
              class="mb-4 ml-4"
              style="vertical-align: middle; display: inline"
            >
              <div class="custom-control custom-switch" v-if="!isAuto">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitch2"
                  v-model="isEdit"
                />
                <label
                  class="custom-control-label text-lable"
                  for="customSwitch2"
                  style="font-size: 14px; font-weight: 500"
                  >Chỉnh sửa</label
                >
              </div>
            </b-row>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-card no-body class="full-width">
          <div>
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div class="wizard-nav" style="background-color: #eef0f8">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Thông tin</div>
                      </div>
                    </div>
                  </div>
                  <div class="wizard-step ml-1" data-wizard-type="step">
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Tệp đính kèm</div>
                      </div>
                    </div>
                  </div>
                  <div class="wizard-step ml-1" data-wizard-type="step">
                    <div
                      class="wizard-wrapper"
                      v-show="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
                    >
                      <div class="wizard-label">
                        <div class="wizard-title">Lịch sử</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom card-shadowless rounded-top-0">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-xl-12">
                      <div
                        class="py-8 px-4 py-lg-15 px-lg-4 pb-5 full-width"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                        <b-row class="col-md-12 mb-1 m-0 p-0" v-if="idNhanh">
                          <div class="alert-header" style="width: 100%">
                            Phiếu được đồng bộ từ Nhanh:
                            <span class="text-primary">{{ idNhanh }}</span>
                          </div>
                        </b-row>
                        <b-row class="mb-5">
                          <b-col lg="6" md="6" sm="12">
                            <b-row>
                              <b-col lg="4" md="4" sm="12">
                                <b-form-group label-class="font-weight-bolder">
                                  <template>
                                    <span>Mã phiếu:</span>
                                  </template>
                                  <p>{{ codeStockBill }}</p>
                                </b-form-group>
                              </b-col>
                              <b-col lg="4" md="4" sm="12">
                                <b-form-group>
                                  <template>
                                    <span>Ngày tạo:</span>
                                  </template>
                                  <p>{{ dateCreate }}</p>
                                </b-form-group>
                              </b-col>
                              <b-col lg="4" md="4" sm="12">
                                <b-form-group>
                                  <template>
                                    <span class="font-weight-bold"
                                      >Ngày nhập kho:</span
                                    >
                                    <span class="text-danger">*</span>
                                  </template>
                                  <date-picker
                                    v-bind:disabled="status != 1"
                                    placeholder="Chọn ngày nhập kho"
                                    class="mb-2 mt-2 form-control form-control-sm"
                                    :config="dpConfigs.date"
                                    v-model="$v.form.dateImport.$model"
                                    :state="validateState('dateImport')"
                                    aria-describedby="input-date-feedback"
                                  ></date-picker>
                                  <b-form-invalid-feedback
                                    >Vui lòng chọn ngày nhập
                                    kho</b-form-invalid-feedback
                                  >
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row class="mt-6">
                              <b-col lg="4" md="4" sm="12">
                                <b-form-group label="Tổng tiền:">
                                  <p>{{ formatMoney(totalPrice) }}</p>
                                </b-form-group>
                              </b-col>
                              <b-col lg="4" md="4" sm="12">
                                <b-form-group label-class="font-weight-bolder">
                                  <template>
                                    <span>Người nhập:</span>
                                  </template>
                                  <p>{{ createBy }}</p>
                                </b-form-group>
                              </b-col>
                              <b-col lg="4" md="4" sm="12">
                                <b-form-group
                                  label-class="font-weight-bolder"
                                  v-if="poCode"
                                >
                                  <template>
                                    <span>Từ phiếu PO:</span>
                                  </template>
                                  <p>
                                    <a
                                      class="font-weight-bold"
                                      v-bind:href="getLinkDoc(poCode)"
                                    >
                                      {{ poCode }}
                                    </a>
                                  </p>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col lg="6" md="6" sm="12">
                                <b-form-group>
                                  <template>
                                    <span class="font-weight-bold">Kho:</span>
                                    <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    class="mt-2 select-style"
                                    v-model="$v.form.inventorySelected.$model"
                                    :options="listInventories"
                                    size="sm"
                                    disabled
                                    :state="validateState('inventorySelected')"
                                    value-field="id"
                                    text-field="name"
                                    @change="listProduct = []"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option
                                        :value="null"
                                        disabled
                                        >Chọn kho</b-form-select-option
                                      >
                                    </template>
                                  </b-form-select>
                                  <b-form-invalid-feedback
                                    >Vui lòng chọn kho</b-form-invalid-feedback
                                  >
                                </b-form-group>
                              </b-col>
                              <b-col
                                lg="6"
                                md="6"
                                sm="12"
                                v-if="showProviderSelected"
                              >
                                <b-form-group>
                                  <template>
                                    <span class="font-weight-bold"
                                      >Nhà cung cấp:</span
                                    >
                                    <span class="text-danger">*</span>
                                  </template>
                                  <b-input-group
                                    :disabled="!isEdit"
                                    class="pt-2"
                                  >
                                    <Autosuggest
                                      :model="searchProvider"
                                      :suggestions="filteredOptionsProvider"
                                      placeholder="nhà cung cấp"
                                      :limit="200"
                                      @select="onSelectedProvider"
                                      @change="onInputChangeProvider"
                                      :state="validateState('providerSelected')"
                                      :errorMessages="'Yêu cầu chọn nhà cung cấp'"
                                      :disabled="!isEdit"
                                      suggestionName="suggestionName"
                                    />
                                    <b-input-group-append v-show="isEdit">
                                      <span
                                        @click="
                                          showOriginalModel = !showOriginalModel
                                        "
                                        class="input-group-text sub-add"
                                      >
                                        <i class="fas fa-plus"></i>
                                      </span>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback
                                      >Vui lòng chọn nhà cung
                                      cấp</b-form-invalid-feedback
                                    >
                                  </b-input-group>
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group :disabled="!isEdit">
                                  <label>Nội dung:</label>
                                  <b-form-textarea
                                    size="sm"
                                    v-model="notes"
                                    :placeholder="'Thêm nội dung...'"
                                    :rows="6"
                                    :max-rows="6"
                                  ></b-form-textarea>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                          <!-- INFO VAT INPUT CONTROLS -->
                          <b-col lg="3" md="3" sm="12" v-if="showInfoVat">
                            <b-form-group :disabled="!isEdit">
                              <label>Chiết khấu:</label>
                              <b-row>
                                <b-col class="pr-0" cols="5">
                                  <b-form-select
                                    class="select-style"
                                    v-model="selectDiscountType"
                                    :options="listAmountType"
                                    size="sm"
                                    value-field="id"
                                    text-field="name"
                                  >
                                  </b-form-select>
                                </b-col>
                                <b-col class="pl-0" cols="7">
                                  <b-form-input
                                    class="input-style text-right"
                                    size="sm"
                                    type="text"
                                    v-model="discountAmount"
                                    v-mask="formater.currency"
                                    placeholder="Chiết khấu"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-form-group>

                            <b-form-group :disabled="!isEdit">
                              <b-checkbox v-model="haveVAT" disabled
                                ><span class="font-weight-bold"
                                  >Có hóa đơn VAT</span
                                ></b-checkbox
                              >
                              <b-row style="margin-top: 0.75rem">
                                <b-col class="pr-0" cols="5">
                                  <b-form-select
                                    class="select-style"
                                    v-model="selectVatType"
                                    :options="listAmountType"
                                    size="sm"
                                    value-field="id"
                                    text-field="name"
                                    disabled-field="notEnabled"
                                    :disabled="!isEdit"
                                  >
                                  </b-form-select>
                                </b-col>
                                <b-col class="pl-0" cols="7">
                                  <b-form-input
                                    class="input-style text-right"
                                    size="sm"
                                    type="text"
                                    v-model="vatAmount"
                                    v-mask="formater.currency"
                                    placeholder="VAT"
                                    :disabled="!isEdit"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-form-group>
                            <b-form-group class="required-control">
                              <label class="font-weight-bold">Kí hiệu:</label>
                              <b-form-input
                                class="input-style"
                                size="sm"
                                type="text"
                                v-model="$v.form.vatSerial.$model"
                                placeholder="Số ký hiệu"
                                :state="validateState('vatSerial')"
                                :disabled="!isEdit"
                              ></b-form-input>
                              <b-form-invalid-feedback
                                v-if="!$v.form.vatSerial.required"
                                >Yêu cầu nhập Số ký
                                hiệu.</b-form-invalid-feedback
                              >
                              <b-form-invalid-feedback
                                v-if="!$v.form.vatSerial.hasSpecialCharacters"
                                >Số ký hiệu không được phép chứa khoảng trắng và
                                các ký tự đặc biệt:
                                <span
                                  class="font-weight-bolder bg-light-danger"
                                >
                                  '`~!@#$%^&amp;*(),;"&lt;&gt;?/\,-_
                                </span></b-form-invalid-feedback
                              >
                              <b-form-invalid-feedback
                                v-if="!$v.form.vatSerial.maxLength"
                                >Số ký hiệu phải ít hơn 12 ký
                                tự.</b-form-invalid-feedback
                              >
                            </b-form-group>

                            <b-form-group class="required-control">
                              <label class="font-weight-bold"
                                >Số hóa đơn VAT:</label
                              >
                              <b-form-input
                                class="input-style"
                                size="sm"
                                type="text"
                                v-model="$v.form.vatCode.$model"
                                placeholder="Số hóa đơn VAT"
                                :state="validateState('vatCode')"
                                :disabled="!isEdit"
                              ></b-form-input>
                              <b-form-invalid-feedback
                                v-if="!$v.form.vatCode.required"
                                >Yêu cầu nhập Số hóa đơn
                                VAT.</b-form-invalid-feedback
                              >
                              <b-form-invalid-feedback
                                v-if="!$v.form.vatCode.numeric"
                                >Chỉ được nhập số.</b-form-invalid-feedback
                              >
                              <b-form-invalid-feedback
                                v-if="!$v.form.vatCode.maxLength"
                                >Số hóa đơn VAT phải ít hơn 12 ký
                                tự.</b-form-invalid-feedback
                              >
                            </b-form-group>
                            <b-form-group class="required-control">
                              <label class="font-weight-bold"
                                >Ngày xuất VAT:</label
                              >
                              <date-picker
                                placeholder="Chọn ngày xuất VAT"
                                class="form-control form-control-sm"
                                :config="dpConfigs.date"
                                v-model="$v.form.vatDate.$model"
                                :disabled="!isEdit"
                              ></date-picker>
                              <b-form-invalid-feedback
                                :state="validateState('vatDate')"
                                v-if="!$v.form.vatDate.required"
                                >Yêu cầu nhập Ngày xuất
                                VAT.</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </b-col>
                          <!-- PAYMENT INPUT CONTROLS -->
                          <b-col lg="3" md="3" sm="12">
                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group :disabled="!isEdit">
                                  <label>Tiền mặt</label>
                                  <b-form-input
                                    class="input-style text-right"
                                    size="sm"
                                    type="text"
                                    v-model="cashAmount"
                                    placeholder="Nhập số tiền mặt"
                                    v-mask="formater.currency"
                                  ></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group>
                                  <label>Tài khoản tiền mặt</label>
                                  <Autosuggest
                                    class="border-radius-none"
                                    :model="!isEdit ? searchCash : ''"
                                    :suggestions="filteredOptionsCash"
                                    placeholder="tài khoản tiền mặt"
                                    :limit="200"
                                    @select="onSelectedCash"
                                    @change="onInputChangeCash"
                                    :disabled="!isEdit"
                                    suggestionName="suggestionName"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group :disabled="!isEdit">
                                  <label>Tiền chuyển khoản</label>
                                  <b-form-input
                                    class="input-style text-right"
                                    size="sm"
                                    type="text"
                                    v-model="transferAmount"
                                    placeholder="Nhập số tiền"
                                    v-mask="formater.currency"
                                  ></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group>
                                  <label>Tài khoản chuyển khoản:</label>
                                  <Autosuggest
                                    class="border-radius-none"
                                    :model="!isEdit ? searchTransfer : ''"
                                    :suggestions="filteredOptionsTransfer"
                                    placeholder="tài khoản tiền mặt"
                                    :limit="200"
                                    @select="onSelectedTransfer"
                                    @change="onInputChangeTransfer"
                                    :disabled="!isEdit"
                                    suggestionName="suggestionName"
                                  />
                                </b-form-group>
                              </b-col>
                            </b-row>
                            <b-row v-if="stockSlipType !== 11">
                              <b-col lg="12" md="12" sm="12">
                                <b-form-group :disabled="!isEdit">
                                  <label>Ngày thanh toán:</label>
                                  <date-picker
                                    placeholder="Chọn ngày thanh toán"
                                    class="form-control form-control-sm"
                                    :config="dpConfigs.date"
                                    v-model="payDate"
                                  ></date-picker>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row class="mb-4">
                          <b-col lg="2" md="2" sm="12" class="pr-0">
                            <b-form-select
                              class="select-style"
                              v-model="selectTypeSearch"
                              :options="listTypeSearch"
                              size="sm"
                              value-field="id"
                              text-field="name"
                              :disabled="!isEdit"
                            ></b-form-select>
                          </b-col>
                          <b-col lg="7" md="7" sm="12" class="pl-0">
                            <b-form-group>
                              <Autosuggest
                                class="border-radius-none"
                                :model="!isEdit ? searchProduct : ''"
                                :suggestions="filteredOptionsProduct"
                                placeholder="tìm kiếm"
                                :limit="10"
                                @select="onSelectedProduct"
                                @change="onInputChangProduct"
                                :disabled="!isEdit"
                                suggestionName="productName"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <span class="font-weight-bolder"
                          >Danh sách sản phẩm:</span
                        >
                        <table
                          class="table table-bordered table-vertical-center table-hover col-12 mt-4"
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                class="title-center"
                                style="width: 15%"
                              >
                                Tên sản phẩm
                              </th>
                              <th
                                scope="col"
                                class="title-center"
                                style="width: 5%"
                              >
                                Tồn
                              </th>
                              <th
                                scope="col"
                                class="title-center"
                                style="width: 20%"
                              >
                                IMEI
                              </th>
                              <th
                                scope="col"
                                class="title-center"
                                style="width: 10%"
                              >
                                Số lượng
                              </th>
                              <th
                                scope="col"
                                class="title-center"
                                style="width: 18%"
                              >
                                Đơn giá
                              </th>
                              <th
                                scope="col"
                                class="title-center"
                                style="width: 14%"
                              >
                                Thành tiền
                              </th>
                              <th
                                scope="col"
                                class="title-center"
                                style="width: 13%"
                              >
                                Chiết khấu
                              </th>
                              <th
                                scope="col"
                                class="title-center"
                                style="width: 5%"
                              ></th>
                            </tr>
                          </thead>

                          <tbody
                            v-for="item in listProductStock"
                            :key="item.id"
                          >
                            <ImportStock
                              v-bind:productItem="item"
                              v-bind:status="status"
                              v-bind:editState="isEdit"
                              v-on:deleteItem="deleteItemInArray"
                              v-on:update="updateItem"
                              :isProductOriginalPriceView="
                                checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')
                              "
                            />
                          </tbody>
                          <tr>
                            <td :colspan="3" class="tdTextAlignPrice">Tổng</td>
                            <td class="tdTextAlignPrice">
                              {{ formatMoney(totalQuantity) }}
                            </td>
                            <td
                              class="tdTextAlignPrice"
                              v-show="
                                checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')
                              "
                            >
                              {{ formatMoney(totalUnitPrice) }}
                            </td>
                            <td
                              class="tdTextAlignPrice"
                              v-show="
                                checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')
                              "
                            >
                              {{ formatMoney(totalPrice) }}
                            </td>
                            <td
                              class="tdTextAlignPrice"
                              v-show="
                                checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')
                              "
                            >
                              {{ formatMoney(totalDiscount) }}
                            </td>

                            <td></td>
                          </tr>
                        </table>

                        <hr
                          class="hr-text"
                          data-content="Bút Toán"
                          style="font-weight: 600"
                        />
                        <b-row v-if="codeStockBill !== ''">
                          <b-col md="12" lg="12" sm="12">
                            <StockSlipTransactions :stockCode="codeStockBill" />
                          </b-col>
                        </b-row>
                        <b-modal
                          v-model="showOriginalModel"
                          hide-footer
                          title="Thêm mới nhà cung cấp"
                        >
                          <b-row class="mb-5">
                            <b-col>
                              <label class="labelInput"
                                >Tên nhà cung cấp:</label
                              >
                              <b-form-input
                                v-model="providerName"
                                size="sm"
                                placeholder="Nhập tên nhà cung cấp"
                              ></b-form-input>
                            </b-col>
                            <b-col>
                              <label class="labelInput"
                                >Số điện thoại nhà cung cấp:</label
                              >
                              <b-form-input
                                v-model="providerPhone"
                                size="sm"
                                placeholder="Nhập số điện thoại nhà cung cấp"
                              ></b-form-input>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-button
                                style="fontweight: 600; width: 70px"
                                variant="primary"
                                size="sm"
                                @click="createProvider()"
                                >Lưu</b-button
                              >
                              <b-button
                                @click="showOriginalModel = !showOriginalModel"
                                style="
                                  margin-left: 10px;
                                  font-weight: 600;
                                  width: 70px;
                                "
                                variant="secondary"
                                size="sm"
                                >Hủy</b-button
                              >
                            </b-col>
                          </b-row>
                        </b-modal>
                      </div>

                      <div
                        class="py-4 mb-5 full-width"
                        data-wizard-type="step-content"
                      >
                        <div class="upload-file col-12">
                          <vue-easy-lightbox
                            escDisabled
                            :visible="previewFile.visible"
                            :imgs="previewFile.imgs"
                            :index="previewFile.index"
                            @hide="handleHidePreviewFile"
                          ></vue-easy-lightbox>

                          <vue-easy-lightbox
                            escDisabled
                            :visible="previewAttachment.visible"
                            :imgs="previewAttachment.imgs"
                            :index="previewAttachment.index"
                            @hide="handleHidePreviewAttachment"
                          ></vue-easy-lightbox>

                          <div>
                            <b-col
                              class="preview-img-container mb-4"
                              v-if="isLoadAttachmentSuccess"
                            >
                              <div>
                                <!--File Previews-->
                                <div class="d-flex">
                                  <div
                                    v-for="(item, index) in attachments"
                                    :key="index"
                                    class="img-container align-items-start"
                                  >
                                    <img
                                      v-if="typecheck(item.fileName)"
                                      :src="item.url"
                                      class="preview-img"
                                      width="80"
                                      height="100"
                                      :alt="item.originalName"
                                      @click="
                                        showMultiplePreViewAttachment(index)
                                      "
                                    />
                                    <img
                                      v-else
                                      src="../../../assets/google-docs.svg"
                                      class="preview-img"
                                      width="80"
                                      height="100"
                                      :alt="item.originalName"
                                      @click="
                                        showMultiplePreViewAttachment(index)
                                      "
                                    />
                                    <i
                                      class="fas fa-times-circle text-danger close"
                                      @click="deleteAttachment(item.id)"
                                    >
                                    </i>
                                    <a
                                      :download="item.fileName"
                                      :href="item.url"
                                      ><i
                                        class="fas fa-arrow-circle-down text-warning remove"
                                      >
                                      </i
                                    ></a>
                                    <!-- <p
                                        style="max-width: 100px"
                                        class="text-center"
                                      >
                                        <a
                                          :download="item.fileName"
                                          :href="item.url"
                                        ><b> {{ ftruncate(item.fileName) }}</b></a>
                                      </p> -->
                                  </div>
                                </div>
                              </div>
                            </b-col>

                            <!--UPLOAD-->
                            <form
                              enctype="multipart/form-data"
                              novalidate
                              class="mb-4"
                            >
                              <b-button
                                size="sm"
                                variant="secondary"
                                @click="openFileUpload()"
                                class="font-weight-bolder mr-2"
                              >
                                <i class="fas fa-paperclip"></i>
                                Đính kèm tệp
                              </b-button>
                              <b-button
                                size="sm"
                                variant="warning"
                                @click="submitFileUpload()"
                                class="font-weight-bolder"
                                v-if="uploadedFiles.length"
                              >
                                <i class="fas fa-upload"></i>
                                Tải tệp lên
                              </b-button>
                              <b-form-file
                                v-model="fileAttach.files"
                                ref="upload-files"
                                id="upload-files"
                                class="mb-2 d-none input-file"
                                multiple
                                :name="uploadFieldName"
                                :disabled="isSaving"
                                v-on:change="
                                  filesChange(
                                    $event.target.name,
                                    $event.target.files,
                                  );
                                  fileCount = $event.target.files.length;
                                "
                              />

                              <p v-if="isSaving">
                                Đang tải {{ fileCount }} tệp...
                              </p>
                              <div v-if="isFailed" class="d-flex">
                                <small class="text-danger"
                                  >Tải tệp thất bại.</small
                                >
                                <small>
                                  <a href="javascript:void(0)" @click="reset()"
                                    >Thử lại</a
                                  >
                                </small>
                                <pre>{{ uploadError }}</pre>
                              </div>
                            </form>

                            <b-col
                              class="preview-img-container mb-4"
                              v-if="isSuccess"
                            >
                              <div>
                                <!--File Previews-->
                                <div class="d-flex">
                                  <div
                                    v-for="(item, index) in uploadedFiles"
                                    :key="index"
                                    class="img-container align-items-start"
                                  >
                                    <img
                                      v-if="typecheck(item.fileName)"
                                      :src="item.url"
                                      class="preview-img"
                                      width="80"
                                      height="100"
                                      :alt="item.originalName"
                                      @click="showMultiplePreViewFile(index)"
                                    />
                                    <img
                                      v-else
                                      src="../../../assets/google-docs.svg"
                                      class="preview-img"
                                      width="80"
                                      height="100"
                                      :alt="item.originalName"
                                      @click="showMultiplePreViewFile(index)"
                                    />

                                    <i
                                      class="fas fa-times-circle text-danger close"
                                      @click="
                                        removeFile(
                                          fileCount,
                                          uploadedFiles.indexOf(item),
                                        )
                                      "
                                    >
                                    </i>

                                    <!-- <p
                                        style="max-width: 100px"
                                        class="text-center"
                                      >
                                        <b> {{ ftruncate(item.fileName) }}</b>
                                      </p> -->
                                  </div>
                                </div>
                              </div>
                            </b-col>
                            <!--SUCCESS-->
                            <p v-if="isSuccess">
                              <b>Tổng số {{ uploadedFiles.length }} tệp</b>
                            </p>
                            <!--FAILED-->
                          </div>
                        </div>
                      </div>
                      <div
                        class="py-4 mb-5 full-width"
                        data-wizard-type="step-content"
                      >
                        <b-container class="bv-example-row">
                          <b-table
                            :fields="historyFields"
                            :items="histories"
                            class="table-bordered table-hover col-md-6"
                          >
                            <template v-slot:cell(createdBy)="row">
                              <p>{{ row.item.createdBy }}</p>
                              <p>{{ row.item.createdAt }}</p>
                            </template>

                            <template v-slot:cell(id)="row">
                              <div
                                class="orderCode"
                                @click="viewHistoryDetail(row.item.id)"
                                style="cursor: pointer; color: #3699ff"
                              >
                                <span v-text="'Chi tiết'"></span>
                              </div>
                            </template>
                          </b-table>
                        </b-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
        </b-card>
        <StockSlipHistory ref="stockslip-history" />
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onSubmit"
          v-if="isEdit"
          >Lưu</b-button
        >
        <b-button
          :style="
            isEdit
              ? 'margin-left: 10px; font-weight: 600; width: 100px'
              : 'font-weight: 600; width: 100px'
          "
          variant="primary"
          size="sm"
          @click="exportExcel"
          >Xuất Excel</b-button
        >
        <router-link to="/stocks" tag="button">
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Trở về</b-button
          >
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import decounce from 'debounce';
import { validationMixin } from 'vuelidate';
import required from 'vuelidate/lib/validators/required';
import numeric from 'vuelidate/lib/validators/numeric';
import map from 'lodash/map';
import VueEasyLightbox from 'vue-easy-lightbox';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import ImportStock from '@/view/components/ImportStock';
import StockSlipTransactions from '@/view/components/StockSlipTransactions';
import { cmdUrl } from '@/utils/apiUrl';
import { currencyMask, unMaskPrice } from '@/utils/common';
import { AMOUNT_TYPE, HISTORY_TYPE, UPLOAD_ENTITY } from '@/utils/enum';
import { BASE_URL, TIME_TRIGGER } from '@/utils/constants';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import JwtService from '@/core/services/jwt.service';
import StockSlipHistory from '@/view/components/stock-slips/StockSlipHistory';
import localData from '@/utils/saveDataToLocal';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { maxLength } from 'vuelidate/lib/validators';
import { STOCK_SLIP_TYPE } from '@/utils/enum';

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;

const hasSpecialCharacters = (string) => {
  const regexSpecialCharacters = /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._ ]/g;
  const normalizedString = string.normalize().trim();
  return !regexSpecialCharacters.test(normalizedString);
};

const MAX_LENGTH_VAT_SERIAL = 12;
const MAX_LENGTH_VAT_CODE = 12;

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
      },
      form: {
        inventorySelected: null,
        providerSelected: null,
        dateImport: '',
        vatCode: '',
        vatDate: '',
        vatSerial: '',
      },
      isSubmitting: false,
      idStock: '',
      codeStockBill: '',
      dateCreate: '',
      listInventories: [],
      listProviders: [],
      listProduct: [],
      count: 0,
      notes: '',
      createBy: '',
      status: 1,
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: 'Tìm kiếm sản phẩm',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      editState: false,
      selectedClassProduct: null,
      timer: null,
      cashAmount: 0,
      cashAccountCode: '',
      transferAmount: 0,
      transferAccountCode: '',
      payDate: null,
      filterModel: {
        cashAccountants: [],
        transferAccountants: [],
      },
      formater: {
        currency: currencyMask,
      },
      showOriginalModel: false,
      providerName: '',
      providerPhone: '',
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: 'Nhập nhà cung cấp',
      filteredOptionsCash: [],
      optionsCash: [
        {
          data: [],
        },
      ],
      searchCash: 'Nhập tài khoản tiền mặt',
      filteredOptionsTransfer: [],
      optionsTransfer: [
        {
          data: [],
        },
      ],
      searchTransfer: 'Nhập tài khoản chuyển khoản',
      isSearching: false,
      idNhanh: null,
      stockSlipType: null,
      isEdit: false,
      selectDiscountType: 1,
      listAmountType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: '%',
        },
      ],
      discountAmount: 0,
      selectVatType: 2,
      vatAmount: 0,
      isAuto: false,
      previewFile: {
        visible: false,
        imgs: '',
        index: 0,
      },
      previewAttachment: {
        visible: false,
        imgs: '',
        index: 0,
      },
      attachments: null,
      isLoadAttachmentSuccess: false,
      fileAttach: {
        files: [],
        fileModels: [],
      },
      uploadedFiles: [],
      fileCount: 0,
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      hashName: null,
      haveVAT: true,
      historyFields: [
        {
          key: 'createdBy',
          label: 'Người tạo',
          thStyle: { textAlign: 'center' },
          tdClass: 'textleft',
        },
        {
          key: 'actionName',
          label: 'Hành động',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'text-left',
        },
        {
          key: 'id',
          label: 'Chi tiết',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'text-left',
        },
      ],
      histories: [],
      poCode: '',
    };
  },
  validations: {
    form: {
      inventorySelected: { required },
      providerSelected: { required },
      dateImport: { required },
      vatCode: {
        required,
        numeric,
        maxLength: maxLength(MAX_LENGTH_VAT_CODE),
      },
      vatSerial: {
        required,
        hasSpecialCharacters,
        maxLength: maxLength(MAX_LENGTH_VAT_SERIAL),
      },
      vatDate: { required },
    },
  },
  components: {
    KTCodePreview,
    ImportStock,
    StockSlipTransactions,
    VueEasyLightbox,
    StockSlipHistory,
    Autosuggest,
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      const discountValue = this.discountAmount
        ? unMaskPrice(this.discountAmount)
        : 0;
      let discountAmount = discountValue;
      if (this.selectDiscountType === AMOUNT_TYPE.PERCENT) {
        discountAmount = (discountValue * total) / 100;
      }

      total = total - discountAmount;
      const vatValue = this.vatAmount ? unMaskPrice(this.vatAmount) : 0;

      let vatAmount = vatValue;
      if (this.selectVatType === AMOUNT_TYPE.PERCENT) {
        vatAmount = (vatValue * total) / 100;
      }

      total = parseInt(total) + parseInt(vatAmount);
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.discount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
    totalUnitPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.price) * 1;
      }
      return total;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    getStateProvider() {
      if (!this.$v.form.providerSelected.$dirty) return null;
      return this.$v.form.providerSelected.required;
    },
    showInfoVat() {
      return this.shouldShowInfoVatOrProvider();
    },
    showProviderSelected() {
      return this.shouldShowInfoVatOrProvider();
    },
  },
  created() {
    this.fetchAccountants('cash');
    this.fetchAccountants('transfer');
    this.fetchStore();
    this.fetchProvider();
    this.getStockById();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu nhập kho', route: '/stocks' },
      { title: 'Cập nhật phiếu nhập kho' },
    ]);
    const hashName = this.$route.hash ? this.$route.hash.split('#')[1] : '';

    this.hashName = hashName === 'attach' ? 'attach' : null;

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: hashName === 'attach' ? 3 : 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      this.searchProduct = option.item.productName;
      let quantity = 0;
      if (this.selectedClassProduct.productType === 1) {
        quantity = 1;
        let index = this.listProductStock.findIndex(
          (item) => item.proId === this.selectedClassProduct.productId,
        );
        if (index === -1) {
          this.count++;
          let pro = {
            id: this.count,
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.quantityInStock != null
                ? this.selectedClassProduct.quantityInStock
                : 0,
            IMEI: '',
            proId: this.selectedClassProduct.productId,
            price: this.selectedClassProduct.originalPrice,
            quantity: quantity,
            discount: 0,
            totalPrice: quantity * this.selectedClassProduct.originalPrice,
            productType: this.selectedClassProduct.productType,
          };
          this.listProductStock.unshift(pro);
        } else {
          this.listProductStock[index].quantity =
            this.listProductStock[index].quantity + quantity;
        }
      } else if (this.selectedClassProduct.productType === 2) {
        this.count++;
        let pro = {
          id: this.count,
          name: this.selectedClassProduct.productName,
          productCode: this.selectedClassProduct.productCode,
          barCode: this.selectedClassProduct.barCode,
          totalQuantityInStock:
            this.selectedClassProduct.quantityInStock != null
              ? this.selectedClassProduct.quantityInStock
              : 0,
          IMEI: '',
          proId: this.selectedClassProduct.productId,
          price: this.selectedClassProduct.originalPrice,
          quantity: quantity,
          discount: 0,
          totalPrice: quantity * this.selectedClassProduct.originalPrice,
          productType: this.selectedClassProduct.productType,
        };
        this.listProductStock.unshift(pro);
      } else if (this.selectedClassProduct.productType === 3) {
        makeToastFaile('Không hỗ trợ nhập kho sản phẩm combo');
      }
      this.searchProduct = null;
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    getStockById() {
      const id = this.$route.query.id;
      ApiService.get(`stocks/${id}`)
        .then(({ data }) => {
          const dataRes = data.data;
          this.codeStockBill = dataRes.code;
          this.dateCreate = moment(dataRes.createdAt).format(
            'HH:mm DD/MM/YYYY',
          );
          this.$v.form.dateImport.$model = moment(dataRes.importDate).format(
            'DD-MM-YYYY',
          );
          this.$v.form.inventorySelected.$model = dataRes.storeId;
          this.$v.form.providerSelected.$model = dataRes.providerId;
          this.searchProvider = dataRes.providerName || 'Nhập nhà cung cấp';
          this.notes = dataRes.description;
          this.createBy = dataRes.createdBy;
          this.status = dataRes.status;
          this.idStock = dataRes.id;
          this.cashAmount = dataRes.cashAmount;
          this.cashAccountCode = dataRes.cashAccountCode;
          this.searchCash = dataRes.cashAccName || 'Nhập tài khoản tiền mặt';
          this.transferAmount = dataRes.transferAmount;
          this.transferAccountCode = dataRes.transferAccountCode;
          this.searchTransfer =
            dataRes.transferAccName || 'Nhập tài khoản chuyển khoản';
          this.payDate = dataRes.payDate
            ? moment(dataRes.payDate).format('DD-MM-YYYY')
            : '';
          this.$v.form.vatDate.$model = dataRes.vatDate
            ? moment(dataRes.vatDate).format('DD-MM-YYYY')
            : '';
          this.vatAmount = dataRes.vatAmount;
          this.selectVatType = dataRes.vatType;
          this.$v.form.vatCode.$model = dataRes.vatCode ? dataRes.vatCode : '';
          this.$v.form.vatSerial.$model = dataRes.vatSerial
            ? dataRes.vatSerial
            : '';
          this.poCode = dataRes.purchaseOrderCode;
          this.haveVAT = true;
          this.idNhanh = dataRes.idNhanh;
          this.stockSlipType = dataRes.type;
          this.discountAmount = dataRes.discountAmount;
          this.selectDiscountType = dataRes.discountType;
          this.isAuto = dataRes.isAuto;
          this.listProductStock = [];
          dataRes.listDetail.forEach((element) => {
            this.count++;
            let listImei = element.productImei.split(',');
            let stt = 1;
            let formatImei = '';
            listImei.forEach((element, index) => {
              formatImei += stt + ')  ' + element;
              if (index !== listImei.length - 1) {
                formatImei += ',';
              }
              stt++;
            });
            let dataItem = {
              ...element,
              name: element.productName,
              IMEI: element.productImei
                .replace(/[,]/g, '\n')
                .replace(/[ ]/g, ''),
              proId: element.productId,
              price: element.unitPrice,
              totalPrice: element.totalAmount,
              formatImei: formatImei.replace(/[,]/g, '\n').replace(/[ ]/g, ''),
              totalQuantityInStock:
                element.totalQuantityInStock != null
                  ? element.totalQuantityInStock
                  : 0,
              discount: element.discount ? element.discount : 0,
              barCode: element.barCode ? element.barCode : '',
              productCode: element.productCode ? element.productCode : '',
            };
            this.listProductStock.push(dataItem);
          });
          this.fetchAccountants('cash');
          this.loadAttachments();
          this.getHistory();
        })
        .catch((response) => {
          makeToastFaile(response);
        });
    },
    fetchStore() {
      ApiService.setHeader();
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.setHeader();
      ApiService.get(
        `productSearch/withMerchant?storeId=${this.inventorySelected}&searchProduct=${textSearch}`,
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          let nextSearchProduct = this.searchProduct;
          this.fetchProduct(nextSearchProduct);
        }
      });
    },
    fetchProvider() {
      ApiService.setHeader();
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProviders = data.data.providers;
        this.optionsProvider[0].data = this.listProviders.map((element) => {
          return {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
            numPaymentDate: element.numPaymentDate,
          };
        });
        this.filteredOptionsProvider = [...this.optionsProvider[0].data];
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = unMaskPrice(item.price);
          this.listProductStock[index].quantity = parseInt(item.quantity);
          this.listProductStock[index].totalPrice = item.totalPrice;
          this.listProductStock[index].discount = unMaskPrice(item.discount);
        }
      });
    },
    onSubmit: async function () {
      if (this.isSubmitting) return;
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (
        this.stockSlipType === STOCK_SLIP_TYPE.IMPORT_STOCK_CHANGE_PRODUCT_TYPE
      ) {
        if (!this.$v.form.inventorySelected.$model) {
          KTUtil.scrollTop();
          return;
        }
      } else {
        if (this.$v.form.$anyError) {
          KTUtil.scrollTop();
          makeToastFaile('Vui lòng nhập đầy đủ thông tin bắt buộc.');
          return;
        }
      }

      let listPro = [];
      this.listProductStock.forEach((element) => {
        let item = {
          id: element.id,
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
          discount: unMaskPrice(element.discount),
        };
        listPro.push(item);
      });
      const data = {
        code: this.codeStockBill,
        id: this.idStock,
        storeId: this.$v.form.inventorySelected.$model,
        providerId: this.$v.form.providerSelected.$model,
        description: this.notes,
        importDate: this.$v.form.dateImport.$model
          ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD',
            )
          : '',
        vatDate: this.$v.form.vatDate.$model
          ? moment(this.$v.form.vatDate.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD',
            )
          : null,
        vatCode: this.$v.form.vatCode.$model,
        vatSerial: this.$v.form.vatSerial.$model,
        listProduct: listPro,
        cashAmount: unMaskPrice(this.cashAmount),
        cashAccountCode: this.cashAccountCode,
        transferAmount: unMaskPrice(this.transferAmount),
        transferAccountCode: this.transferAccountCode,
        payDate: this.payDate
          ? moment(this.payDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        vatType: this.selectVatType,
        vatAmount: this.vatAmount ? unMaskPrice(this.vatAmount) : 0,
        discountType: this.selectDiscountType,
        discountAmount: this.discountAmount
          ? unMaskPrice(this.discountAmount)
          : 0,
      };

      ApiService.put('stock-slips/import-stock-slip', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.listProduct = [];
            makeToastSuccess(data.message);
            this.$router.push({
              path: '/stocks',
            });
          } else {
            this.isSubmitting = false;
            makeToastFaile(data.message);
          }
        })
        .catch((response) => {
          this.isSubmitting = false;
          makeToastFaile(response);
        });
    },
    fetchAccountants: async function (mode) {
      this.optionsCash[0].data = [];
      let params = {
        type: mode === 'cash' ? '1' : '2',
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (this.$v.form.inventorySelected.$model !== null && mode === 'cash') {
        url = 'accountants/getByStore';
        params = {
          type: mode === 'cash' ? '1' : '2',
          storeId: this.$v.form.inventorySelected.$model,
        };
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        map(data, (item) => {
          let element = {
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          if (mode === 'cash') {
            this.optionsCash[0].data.push(element);
          } else if (mode === 'transfer') {
            this.optionsTransfer[0].data.push(element);
          }
        });
        this.filteredOptionsCash = [...this.optionsCash[0].data];
        this.filteredOptionsTransfer = [...this.optionsTransfer[0].data];
      });
    },
    exportExcel: async function () {
      ApiService.setHeader();
      let baseUrl = BASE_URL;
      //let baseUrl = 'http://localhost:3000/api/';
      let url = `${baseUrl}stocks/export-excel/${this.idStock}?type=1`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex,
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        // let fileName = 'Hóa đơn nhập NCC';
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    createProvider: async function () {
      if (!this.providerName) {
        return;
      }
      const data = {
        name: this.providerName,
        phoneNo: this.providerPhone,
        status: 1,
      };

      ApiService.post('providers', data).then((response) => {
        const { status, message } = response.data.data;
        if (status === 1) {
          this.fetchProvider();
          this.showOriginalModel = !this.showOriginalModel;
          makeToastSuccess(message);
        } else {
          this.showOriginalModel = !this.showOriginalModel;
          makeToastFaile(message);
        }
      });
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.$v.form.providerSelected.$model = option.item.id;
      const numDate = option.item.numPaymentDate;
      if (numDate > 0) {
        this.payDate = moment().add(numDate, 'days').format('DD/MM/YYYY');
      }
      if (!numDate) {
        this.payDate = '';
      }
    },
    onInputChangeProvider(text) {
      this.$v.form.providerSelected.$model = null;
      this.searchProvider = text;
      if (this.stockSlipType === 11) {
        this.debounceInput();
      } else {
        const filteredData = this.optionsProvider[0].data
          .filter((item) => {
            return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
          })
          .slice(0, this.limit);
        this.filteredOptionsProvider = [...filteredData];
      }
    },
    onSelectedCash(option) {
      this.searchCash = option.item.name;
      this.cashAccountCode = option.item.code;
    },
    onInputChangeCash(text) {
      this.searchCash = text;
      const filteredData = this.optionsCash[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsCash = [...filteredData];
    },
    onSelectedTransfer(option) {
      this.searchTransfer = option.item.name;
      this.transferAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      this.searchTransfer = text;
      const filteredData = this.optionsTransfer[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsTransfer = [...filteredData];
    },
    debounceInputProduct: decounce(function () {
      this.nextSearchProduct();
    }, TIME_TRIGGER),
    nextSearchProduct() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    },
    getListCustomer() {
      this.optionsProvider[0].data = [];
      let text = this.searchProvider;
      ApiService.setHeader();
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          const customers = data.data.data;
          customers.map((element) => {
            let cus = {
              id: element.id,
              name: element.fullName,
              phoneNo: element.phoneNo,
              suggestionName: element.phoneNo + '- ' + element.fullName,
            };
            this.optionsProvider[0].data.push(cus);
          });
          this.filteredOptionsProvider = [...this.optionsProvider[0].data];
        },
      );
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    deleteAttachment(id) {
      ApiService.delete(`${cmdUrl.File.root}/${id}`).then((deleteResponse) => {
        if (deleteResponse.status === 200) {
          if (deleteResponse.data.data === true) {
            const index = this.attachments.findIndex((item) => item.id === id);
            this.attachments.splice(index, 1);
          }
        }
      });
    },
    loadAttachments() {
      this.isLoadAttachmentSuccess = false;
      ApiService.query('file', {
        params: {
          entity: UPLOAD_ENTITY.STOCK_SLIP,
          entityId: this.codeStockBill,
        },
      })
        .then(async (resp) => {
          if (resp.status === 200) {
            const attachments = resp.data.data.map((item) => ({
              id: item.id,
              fileName: item.fileName,
              originalName: item.fileName,
            }));

            this.attachments = await Promise.all(
              attachments.map(async ({ fileName, ...rest }) => {
                const resp = await axios.get(
                  `${cmdUrl.File.download}?filename=${fileName}`,
                  {
                    responseType: 'arraybuffer',
                    headers: {
                      Authorization: `Bearer ${JwtService.getToken()}`,
                    },
                  },
                );
                const contentType = resp.headers['content-type'];
                const base64 = `data:${contentType};base64,${Buffer.from(
                  resp.data,
                ).toString('base64')}`;
                return {
                  ...rest,
                  url: base64,
                  fileName,
                };
              }),
            );

            this.isLoadAttachmentSuccess = true;
          }
        })
        .catch(() => {
          this.isLoadAttachmentSuccess = false;
        });
    },
    handleHidePreviewFile() {
      this.previewFile.visible = false;
    },
    showMultiplePreViewFile(indexOfFile) {
      const files = this.uploadedFiles.map((file) => {
        return {
          src: file.url,
          title: file.fileName,
        };
      });
      this.previewFile.imgs = files;
      this.previewFile.index = indexOfFile;
      this.previewFile.visible = true;
    },
    handleHidePreviewAttachment() {
      this.previewAttachment.visible = false;
    },
    showMultiplePreViewAttachment(indexOfFile) {
      const files = this.attachments.map((file) => {
        return {
          src: file.url,
          title: file.fileName,
        };
      });
      this.previewAttachment.imgs = files;
      this.previewAttachment.index = indexOfFile;
      this.previewAttachment.visible = true;
    },
    openFileUpload() {
      document.getElementById('upload-files').click();
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    //This is where we implement the storage service
    save(formData) {
      this.currentStatus = STATUS_SAVING;
      //Implement your own storage service when upload() is called.
      //For test purposes, I added a delay service
      this.upload(formData)
        .then((x) => {
          this.uploadedFiles = this.uploadedFiles.concat(x);
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch((err) => {
          console.log(err);
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    upload(formData) {
      const photos = formData.getAll('photos');
      const promises = photos.map((x) =>
        this.getImage(x).then((img) => ({
          id: img,
          originalName: x.name,
          fileName: x.name,
          url: img,
        })),
      );
      return Promise.all(promises);
    },
    getImage(file) {
      return new Promise((resolve) => {
        const fReader = new FileReader();
        const img = document.createElement('img');

        fReader.onload = () => {
          img.src = fReader.result;
          resolve(this.getBase64Image(img));
        };

        fReader.readAsDataURL(file);
      });
    },
    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = img.src;
      return dataURL;
    },
    //Returns true for image data types for others false, for preview purpose
    typecheck(oInput) {
      var _validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];
      if (oInput.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            oInput
              .substr(
                oInput.length - sCurExtension.length,
                sCurExtension.length,
              )
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    //Removes the selected file from the array.
    removeFile(fileCount, key) {
      fileCount = this.uploadedFiles.length;
      if (fileCount === 1) {
        this.uploadedFiles.splice(key, 1);
        this.reset();
      } else {
        this.uploadedFiles.splice(key, 1);
      }
    },
    //Func to truncate long filenames to short ones to display
    ftruncate(n) {
      // var len = 10;
      var ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
      var filename = n.replace('.' + ext, '');
      // if (filename.length <= len) {
      //   return n;
      // }
      // filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
      return filename + '.' + ext;
    },
    // Handle file changes
    filesChange(fieldName, fileList) {
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });
      // Save it to cloud
      this.save(formData);
    },
    submitFileUpload() {
      let formData = new FormData();
      for (const i of Object.keys(this.fileAttach.files)) {
        formData.append('fileUploads', this.fileAttach.files[i]);
      }
      this.currentStatus = STATUS_SAVING;
      const context = this;
      axios
        .post(
          `${cmdUrl.File.upload}?entityId=${this.codeStockBill}&entity=${UPLOAD_ENTITY.STOCK_SLIP}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${JwtService.getToken()}`,
            },
          },
        )
        .then((resp) => {
          if (resp.status === 200) {
            context.currentStatus = STATUS_SUCCESS;
          }
        })
        .catch(() => {
          context.currentStatus = STATUS_FAILED;
        });
    },
    getHistory() {
      this.histories = [];
      const params = {
        referenceId: this.idStock,
        type: HISTORY_TYPE.STOCK_SLIP,
      };
      ApiService.query(`histories/by-type`, { params })
        .then(({ data }) => {
          if (data.status === 1) {
            this.histories = data.data;
          } else {
            makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(() => {
          makeToastFaile('Lỗi');
        });
    },
    viewHistoryDetail(historyId) {
      this.$refs['stockslip-history'].showModal(historyId);
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    getLinkDoc(code) {
      return `#/purchase-orders/info-purchase-orders?id=${code}`;
    },
    shouldShowInfoVatOrProvider() {
      const skipTypes = [
        STOCK_SLIP_TYPE.IMPORT_STOCK_LOSS,
        STOCK_SLIP_TYPE.IMPORT_STOCK_CHANGE_PRODUCT_TYPE,
      ];
      return !skipTypes.includes(this.stockSlipType);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss';
@import '@/assets/sass/pages/wizard/wizard-4.scss';

.update-stock {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  .title-center {
    text-align: center;
  }

  .input-group-append {
    cursor: pointer;
    border-radius: 0.28rem;
  }

  .input-group-append:hover i {
    color: #3699ff;
  }

  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
    font-weight: 600;
  }
  .alert-header {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  .upload-file {
    .dropbox {
      margin: auto;
      width: 70%;
      background: #f8f8f8;
      border-radius: 20px;
      color: dimgray;
      padding: 10px 10px;
      min-height: 200px;
      position: relative;
      cursor: pointer;
    }

    .input-file {
      opacity: 0; /* invisible but it's there! */
      left: 0px;
      width: 100%;
      height: 200px;
      position: absolute;
      cursor: pointer;
    }

    .dropbox:hover {
      background: #e8f5e9;
    }

    .dropbox p {
      font-size: 1.2em;
      text-align: center;
      padding: 50px 0;
    }
    .img-container {
      position: relative;
      display: inline-block;
      width: 80px;
      margin: 0px 10px;
    }
    .preview-img {
      max-width: 80px;
      padding: 10px;
      object-fit: contain;
      border: 1px solid #eeecff;
      border-radius: 5px;
    }
    .preview-img-container {
      border: 2px dashed #eeecff;
      padding: 2rem 1rem;
    }

    .preview-box {
      display: inline-block;
    }
    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 15px;
    }
    .remove {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 15px;
    }
    .cancel {
      color: #545454;
      text-decoration: none;
    }
  }
}
</style>
